import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { getNotificationList } from "./../../services";
import {
  startLoader,
  stopLoader,
  closeNotificationBar,
} from "./../../store/reducers/generalActions";
import { resetNotificationCount } from "./../../store/reducers/auth";
import MSG, { notifyError, randomKey } from "./../../utils/Helper";

import classNames from "classnames";
import styles from "../../styles/notificationResponsive.module.css";

class Updates extends Component {
  state = {
    notificationList: [],
  };

  async componentDidMount() {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await getNotificationList();
      if (res.success == true) {
        this.setState({
          notificationList: [...res.response],
        });
        this.props.dispatch(resetNotificationCount());
      } /*else {
            notifyError(res.message);
          } */
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  }

  render() {
    return (
      <div>
        {this.state.notificationList.length != 0 ? (
          this.state.notificationList.map((item) => (
            <div
              className={`notif-content ${
                item.is_new_notify == 1 ? "notify-tile-color" : ""
              }`}
              key={randomKey()}
            >
              <span className="notif-header">
                <b>{item.notif_title}</b>
              </span>
              <br />
              <div className={"notif-msg"} key={randomKey()}>
                <span className={classNames(styles["update-parah"])}>
                  {item.notif_msg}
                </span>
              </div>
              <span className={classNames(styles["update-date"], "notif-date")}>
                {moment.utc(item.created_at).local().format("LLL")}
              </span>
            </div>
          ))
        ) : (
          <div>
            <div className="no-notify-found">
              <span>
                <i className="fas fa-bell bell"></i>
              </span>
            </div>
            <div style={{ marginLeft: "95px", marginTop: "5px" }}>
              <h5>You’re all caught up</h5>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Updates);
