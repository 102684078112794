import ReactSelect, {components} from "react-select";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";

const Option = props => {
	return (
		<div>
			<components.Option {...props}>
				{/* <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "} */}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const DemoGenerateQuestionsSpecialist = ({
	setState,
	state,
	radioButtonValue,
	setSubject,
	getSortedOptions,
	setTopicFormula,
	handleGenerateQuestion,
}) => {
	const DIAGRAM_OPTIONS = [
		{value: 1, label: "Diagrams"},
		{value: 2, label: "Graphs"},
		{value: 3, label: "Tables"},
		{value: 4, label: "All Possible"},
	];
	const DIFFICULTY_OPTIONS = [
		{value: "Easy", label: "Easy"},
		{value: "Medium", label: "Medium"},
		{value: "Hard", label: "Hard"},
	];

	const CUSTOM_STYLES = {
		option: (provided, state) => ({
			...provided,
		}),

		container: base => ({
			...base,

			// border: this.state.occupation_message ? "1px solid red" : "",
			borderRadius: 5,
		}),

		// control: () => ({
		//   // none of react-select's styles are passed to <Control />
		//   border:"1px solid red"
		// }),
		menu: (provided, state) => ({
			...provided,
		}),
		placeholder: base => ({
			...base,
			fontSize: 13,
			color: "#8898aa",
		}),
	};

	return (
		<div className='question-generate-tab-outer maxw-820'>
			<div className=''>
				<label
					for='selectques_type'
					className={classNames(
						styles["question-generate-tab-label"],
						"custom-login-field--label"
					)}
					style={{fontWeight: 500, color: "#344054", gap: "1rem"}}
				>
					{" "}
					Select Subject
				</label>
				<div className=' text-left'>
					<ReactSelect
						hideSelectedOptions={false}
						maxMenuHeight={200}
						components={{
							Option,
						}}
						options={state.subject}
						className={classNames(styles["question-generate-tab-label"])}
						type='text'
						// defaultValue={state.subject[0]}
						id='selectques_type'
						// onInputChange={(e) => handleCompanyinput(e)}
						onChange={setSubject}
						value={state.subject_name}
						styles={state.customStyles}
					/>
				</div>
				{state.errorEvent === "subject_name" && (
					<span className='error-msg-expertise'>{state.errorMsg}</span>
				)}
			</div>
			<div className='mt-3'>
				<label
					for='selectques_type'
					className={classNames(
						styles["question-generate-tab-label"],
						"custom-login-field--label"
					)}
					style={{fontWeight: 500, color: "#344054", gap: "1rem"}}
				>
					{" "}
					Select Preferred Content Type{" "}
				</label>
				<div style={{gap: "1rem"}} className='d-flex text-left'>
					<label
						className={classNames(
							styles["question-generate-tab-label"],
							"mordern-radiobox-label my-0"
						)}
					>
						<input
							name='topic_'
							type='radio'
							checked={[1, 3].includes(state.key_pcma) ? true : false}
							onChange={e => radioButtonValue(e)}
						/>
						<span style={{top: "1px"}} className='design'></span> Topic
						Based
					</label>
					<label
						style={
							state.disableTopicFormula
								? {opacity: "0.7", cursor: "no-drop"}
								: null
						}
						className={classNames(
							styles["question-generate-tab-label"],
							"mordern-radiobox-label my-0"
						)}
					>
						<input
							name='formula_'
							type='radio'
							disabled={state.disableTopicFormula}
							checked={state.key_pcma === 2 ? true : false}
							onChange={e => radioButtonValue(e)}
						/>
						<span style={{top: "1px"}} className='design'></span> Formula
						Based
					</label>
				</div>
				{state.errorEvent === "topic_or_formula" && (
					<span className='error-msg-expertise'>{state.errorMsg}</span>
				)}
			</div>
			<div className='mt-3'>
				<label
					for='selectques_type'
					className={classNames(
						styles["question-generate-tab-label"],
						"custom-login-field--label"
					)}
					style={{fontWeight: 500, color: "#344054", gap: "1rem"}}
				>
					{" "}
					{state.key_pcma === 2 ? "Select Formula" : "Select Topic"}{" "}
				</label>
				<div className=' text-left'>
					<ReactSelect
						hideSelectedOptions={false}
						maxMenuHeight={200}
						components={{
							Option,
						}}
						options={getSortedOptions()}
						type='text'
						placeholder={
							state.key_pcma === 2 ? "Select Formula" : "Select Topic"
						}
						id='selectques_type'
						className={classNames(styles["question-generate-tab-label"])}
						onChange={setTopicFormula}
						value={state.topic_formula_name}
						styles={state.customStyles}
					/>
				</div>
				{state.errorEvent === "topic_formula" && (
					<span className='error-msg-expertise'>{state.errorMsg}</span>
				)}
			</div>
			<div style={{gap: "1rem"}} className='mt-3 flex-row d-flex'>
				<div className='position-relative' style={{width: "50%"}}>
					<label
						for='selectques_type'
						className={classNames(
							styles["question-generate-tab-label"],
							"form-label d-flex align-items-center"
						)}
						style={{
							fontWeight: 500,
							color: "#344054",
							gap: "1rem",
						}}
					>
						{" "}
						Difficulty Level{" "}
					</label>
					<div className=' text-left'>
						<ReactSelect
							className={classNames(
								styles["question-generate-tab-label"]
							)}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							maxMenuHeight={200}
							options={DIFFICULTY_OPTIONS}
							isClearable
							onChange={handleGenerateQuestion}
							type='text'
							placeholder={"Easy"}
							id='selectques_type'
							name='ques_count'
							styles={CUSTOM_STYLES}
						/>
					</div>
				</div>

				<div className='position-relative' style={{width: "50%"}}>
					<label
						className={classNames(
							styles["question-generate-tab-label"],
							"form-label d-flex align-items-center"
						)}
						style={{
							fontWeight: 500,
							color: "#344054",
							gap: "1rem",
							position: "relative",
						}}
					>
						{" "}
						Include Visuals{" "}
						<span
							style={{
								fontSize: "8px",
								right: "1px",
								position: "absolute",
								background: "#ffc107",
							}}
							className='badge text-white badge-warning'
						>
							Coming Soon!
						</span>
					</label>
					<ReactSelect
						className={classNames(styles["question-generate-tab-label"])}
						hideSelectedOptions={false}
						maxMenuHeight={200}
						isClearable
						isDisabled={true}
						type='text'
						options={DIAGRAM_OPTIONS}
						placeholder={"None."}
						id='selectdiagram'
						name='diagram'
						styles={CUSTOM_STYLES}
					/>
				</div>
			</div>
			<div
				className={classNames(
					"btn-flex100",
					styles["specialist-btn-margin-top-custom"]
				)}
			>
				<button
					onClick={handleGenerateQuestion}
					type='submit'
					className={classNames(
						styles["question-generate-submit"],
						"btn--submit"
					)}
				>
					Generate Questions
				</button>
			</div>
		</div>
	);
};

export default DemoGenerateQuestionsSpecialist;
